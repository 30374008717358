<template>
    <div id="wrapper">
      <div class="content">
        <img src="@/assets/honda_logo.png" alt="logo honda" style="min-width: 300px; width: 80%; max-width: 450px; display: block; margin: auto;"/>
        <hooper :settings="hooperSettings" style="height: 420px; max-width: 1200px; margin: 0 auto 50px auto;">
          <slide v-for="(nft, index) in nfts"
                 :key="nft.token_id"
                 :index="index"
          >
            <Card :data="nft"/>
          </slide>
          <hooper-navigation slot="hooper-addons" class="collection-navigation"></hooper-navigation>
          <hooper-pagination slot="hooper-addons"></hooper-pagination>
        </hooper>
        <div style="width: 80%; max-width: 1125px; padding: 20px; background: rgba(0,0,0,.8); margin: auto; border-radius: 20px;">
          <img src="@/assets/hondaxarthritis.png" alt="logo honda x arthritis" style="width: 80%; max-width: 450px; display: block; margin: auto; margin-bottom: 50px;"/>
          <h3 style="text-align: center;">{{$t('honda.title')}}</h3>
          <p style="text-align: justify;">
            {{$t('honda.description')}}
          </p>
        </div>
      </div>
    </div>
</template>

<script>
import { mapMutations } from 'vuex'
import Card from '@/components/Cards/Card'
import { Hooper, Navigation as HooperNavigation, Pagination as HooperPagination, Slide } from 'hooper'
const metadata = require('@/assets/honda.json')
export default {
  name: 'hondaSubmarket',
  components: { Card, HooperPagination, Hooper, Slide, HooperNavigation },
  data () {
    return {
      metadata,
      nfts: [],
      hooperSettings: {
        itemsToShow: 1,
        centerMode: false,
        wheelControl: false,
        breakpoints: {
          1200: {
            itemsToShow: 3
          }
        }
      }
    }
  },
  methods: {
    ...mapMutations(['setCustomClass']),
    fetchData () {
      this.metadata.asas.forEach(asa => {
        const url = `/api/nft/${asa.id}`
        this.$http.get(url).then(response => {
          if (response.data && response.data.nft) {
            this.nfts.push(response.data.nft)
          }
        }).catch(err => {
          console.log(err)
        })
      })
    }
  },
  beforeDestroy () {
    this.setCustomClass({ customClass: '' })
  },
  mounted () {
    this.setCustomClass({ customClass: 'honda' })
    this.fetchData()
  }
}
</script>

<style scoped>
#wrapper {
  width: 100vw;
  min-height: 100vh;
  background: url(../../../assets/marketHeader/honda_bg.png) no-repeat center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
  overflow-x: hidden;
}
.content {
  min-height: min-content;
  padding: 75px;
  width: 100%;
}
.hooper-slide{
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity .5s ease;
  pointer-events: all;
}

.hooper-slide[aria-hidden=true]{
  opacity: 0;
  pointer-events: none;
}
</style>

<style>
#app.honda, #app.honda.dark {
  --blue: #d51113;
  --red: #d51113;
  --background: #010206;
  --foreground: #010206;
  --foreground-transparent: rgba(29,34,45,.5);
  --foreground-blur: rgba(29,34,45,.5);
  --nav-background: #0a1423;
  --fake-blur: #1c1d20eb;
  --headings: #202231;
  --text: #ffffff;
  --textlight: rgba(218, 228, 255, 0.6);
  --accent: var(--blue);
  --complement: #fff;
  --blur-overlay: rgba(14, 17, 23, .5);
  --light-shadow: rgba(0, 0, 0, .1) 0px 0px 20px;
  --shadow: rgba(0, 0, 0, .1) 0px 0px 2px, rgba(0, 0, 0, .3) 0px 0px 20px;
  --strong-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  --accent-shadow: 0 0 .5vw rgba(0,0,0,.1), 0 0 10vw rgba(202, 49, 112, 0.3);
  --gradient: linear-gradient(180deg, #a12828, #d51113);
  --background-alt: #1d222d;
  --foreground-alt: #0e1117;
  --invert: 1;
  --pop-up-background: rgba(14, 17, 23, 0.9);
  background: linear-gradient(180deg,#0a1423,#14285f 50%,#14a0b4);
  min-height: 100vh;
  transition: background .5s ease;
  width: 100%;
  overflow-x: hidden;
}

#app.honda .view-header{
  background: transparent;
}
</style>
